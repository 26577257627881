<template>
  <div class="vx-row">
    <div class="vx-col w-full">
      <!-- FORM START -->
      <div class="vx-row mb-3">
        <div class="vx-col w-full">
          <vs-input class="w-full" v-validate="'required'" name="name" label="Name" v-model="name"></vs-input>
          <span class="text-danger text-sm" v-show="errors.has('name')">{{errors.first('name')}}</span>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col w-full">
          <vs-input class="w-full" v-validate="'required'" name="email" label="Email" v-model="email"></vs-input>
          <span class="text-danger text-sm" v-show="errors.has('email')">{{errors.first('email')}}</span>
          <span class="text-danger text-sm" v-show="formError.email" v-for="(tr,i) in formError.email" :key="i">{{tr}}</span>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col w-full">
          <vs-checkbox v-model="is_admin">As Administrator</vs-checkbox>
        </div>
      </div>
      <vs-divider></vs-divider>
      <div v-show="id" class="vx-row mb-3">
        <div class="vx-col w-full">
          <vs-checkbox v-model="is_pw_change">Password Change ?</vs-checkbox>
        </div>
      </div>
      <div v-if="is_pw_change" class="vx-row mb-3">
        <div class="vx-col w-full">
          <vs-input ref="password" type="password" class="w-full" v-validate="'required'" data-vv-validate-on="blur" name="password" autocomplete="new-password" label="Password" v-model="password"></vs-input>
          <span class="text-danger text-sm" v-show="errors.has('password')">{{errors.first('password')}}</span>
        </div>
      </div>
      <div v-if="is_pw_change" class="vx-row mb-3">
        <div class="vx-col w-full">
          <vs-input type="password" class="w-full" v-validate="'required|confirmed:password'" name="password_confirmation" data-vv-validate-on="blur" label="Password Confirmation" v-model="password_confirmation"></vs-input>
          <span class="text-danger text-sm" v-show="errors.has('password_confirmation')">{{errors.first('password_confirmation')}}</span>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col w-full">
          <vs-checkbox v-model="is_active">Active</vs-checkbox>
        </div>
      </div>
      <vs-divider></vs-divider>
      <div class="vx-row mb-3">
          <div class="vx-col w-full" v-if='!is_contact && role_id == null'>
            <browse-contact class="w-full" name="contact_id" label="Contact" v-model="contact_id" v-on:onChangeContact="changeContact"></browse-contact>
          </div>
      </div>
      <div class="vx-row mb-3" v-if="contact_id == null">
        <div class="vx-col w-full">
          <small class="ml-2">Role</small>
          <v-select v-model="role_id" :options="roles" name="role_id" label="name" :reduce="e => e.id"></v-select>
        </div>
      </div>
      <!-- FORM END -->
    </div>
    <div class="vx-col w-full flex mt-3">
      <vs-button @click="store" color="primary" icon-pack="feather" icon="icon-save">Save</vs-button>&nbsp;
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import BrowseContact from '@browse/Contact.vue'
import vSelect from 'vue-select'

export default {
  props:{
    id: {
      type: String,
      default: null
    }
  },
  components:{
    BrowseContact,
    vSelect
  },
  data(){
    return {
      is_contact : null,
      name: "",
      email: "",
      password: "",
      password_confirmation: "",
      is_admin: false,
      is_pw_change: false,
      is_active: true,
      contact_id: null,
      role_id: null,
      formError: {}
    }
  },
  computed:{
    ...mapState({
      roles: state => state.roles.all
    })
  },
  methods:{
    ...mapActions({
      dispatchStore: 'users/store',
      dispatchUpdate: 'users/update',
      dispatchShow: 'users/show',
      dispatchUserInfo : 'updateUserInfo',
      getUser : 'auth/getUser',
      dispatchAllRole: 'roles/all',
    }),
    store(){
      this.formError={}
      this.$validator.validateAll().then(async res => {
        if(!res) return false;
        let payload = {
          id: this.id,
          name: this.name,
          email: this.email,
          is_admin: this.is_admin,
          password: this.password,
          is_active: this.is_active, contact_id: this.contact_id,
          role_id: this.role_id
        }
        try {
          if (this.id) {
            let {data} = await this.dispatchUpdate(payload)
          } else {
            let {data} = await this.dispatchStore(payload)
          }
          let data = await this.getUser()
          this.dispatchUserInfo(data)
          this.$emit('on-success', data)
        } catch (error) {
          if (error.status==422) {
            for(let x in error.data) {
                this.$vs.notify({
                    title: 'Oops!',
                    text: error.data[x][0],
                    color: 'danger'
                })
                break
            }
          } else {
            this.$vs.notify({
              title: 'Oops!',
              text: error.data.message,
              color: 'danger'
            })
          }
        }
      })
    },
    async getDetail(){
      let { data } = await this.dispatchShow(this.id)
      this.name = data.name
      this.email = data.email
      this.is_admin = data.is_admin
      this.is_active = data.is_active
      this.contact_id = data.contact_id
      this.role_id = data.role_id
    },
    changeContact(data) {
      this.role_id = null;
    }
  },
  mounted(){
    if(this.$route.query.contact_id) {
        this.is_contact = true
        this.contact_id = this.$route.query.contact_id
    }
    // console.log(this.ids)
    if (this.id) {
      this.getDetail()
    } else {
      this.is_pw_change = true
    }
    this.dispatchAllRole();
  }
}
</script>

<style>

</style>